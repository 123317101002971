import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e543a77"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.componentId,
    class: "input-date"
  }, [
    _createVNode(_component_AppInput, {
      modelValue: _ctx.state.y,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.y) = $event)),
      disabled: _ctx.disabled,
      min: 0,
      max: 9999,
      label: "year",
      type: "number",
      "input-id": `${_ctx.componentId}-y`,
      styled: _ctx.styled,
      "aria-label": `${_ctx.label} ${_ctx.$t('form.year')}`,
      class: "input-date-firefox"
    }, null, 8, ["modelValue", "disabled", "input-id", "styled", "aria-label"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.$t('form.year')), 1),
    (_ctx.isAllDate)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_AppInput, {
            modelValue: _ctx.state.m,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.m) = $event)),
            disabled: _ctx.disabled,
            min: 1,
            max: 12,
            "input-id": `${_ctx.componentId}-m`,
            label: "month",
            type: "number",
            styled: _ctx.styled,
            "aria-label": `${_ctx.label} ${_ctx.$t('form.month')}`,
            class: "input-date-firefox"
          }, null, 8, ["modelValue", "disabled", "input-id", "styled", "aria-label"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('form.month')), 1)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.isAllDate)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_AppInput, {
            modelValue: _ctx.state.d,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.d) = $event)),
            disabled: _ctx.disabled,
            min: 1,
            max: 31,
            "input-id": `${_ctx.componentId}-d`,
            label: "day",
            type: "number",
            styled: _ctx.styled,
            "aria-label": `${_ctx.label} ${_ctx.$t('form.day')}`,
            class: "input-date-firefox"
          }, null, 8, ["modelValue", "disabled", "input-id", "styled", "aria-label"]),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('form.day')), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}