
import { defineComponent, reactive, watchEffect } from 'vue'
import { StyleName, styleNames } from '../../data/def'
import AppInput from '../atoms/AppInput.vue'

export default defineComponent({
  components: {
    AppInput,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: 'ja',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styled: {
      type: String,
      default: 'is-normal',
      validator: (val: StyleName): boolean => {
        return styleNames.includes(val)
      },
    },
    isAllDate: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup (props, context) {
    const state = reactive({
      y: props.modelValue ? props.modelValue.substring(0, 4) : null,
      m: props.modelValue ? props.modelValue.substring(5, 7) : null,
      d: props.modelValue ? props.modelValue.substring(8) : null,
    })

    watchEffect(() => {
      const y = ('0000' + (state.y || 0)).slice(-4)
      const m = ('0000' + (state.m || 0)).slice(-2)
      const d = ('0000' + (state.d || 0)).slice(-2)
      context.emit('update:modelValue', `${y}-${m}-${d}`)
    })

    return {
      state,
    }
  },
})
